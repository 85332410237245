@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes dropIn {
    from {
        opacity: 0;
        transform: translate(0, -10px);
    }
    to {
        opacity: 1;
        transform: translate(0, 0px);
    }
}
@keyframes dropInDelayed {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translate(0, -10px);
    }
    70% {
        opacity: 1;
        transform: translate(0, 0px);
    }
}

@keyframes logoText1 {
    0% {
        opacity: 0;
        transform: translate(0, -30px);
    }

    15% {
        opacity: 1;
    }

    25% {
        transform: translate(0, 0px);
    }
    35% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}
@keyframes logoText2 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }
    65% {
        opacity: 1;
    }
}

/* The element to apply the animation to */
.fadeIn {
    animation-name: fadeIn;
    animation-duration: 3s;
}

.logoText1 {
    animation-name: logoText1;
    animation-duration: 5s;
    opacity: 0;
}

.logoText2 {
    animation-name: logoText2;
    animation-duration: 5s;
}
.dropIn {
    animation-name: dropIn;
    animation-duration: 2s;
}
.dropInDelayed {
    animation-name: dropInDelayed;
    animation-duration: 5s;
}
