body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.drawerstyle div {
	background-color: #242363 !important;
	overflow: hidden;
	width: 60px;
}

.logoadjust {
	position: relative;
	top: 9px;
	left: 12px;
	height: 60px;
}

.f_menu_icon {
	padding-top: 8px;
	padding-bottom: 8px;
}

.top_bar {
	background-color: #f1f3f4 !important;
}
:root {
	overflow: hidden;
}
.MuiAccordionSummary-content.Mui-expanded {
	margin: 0 !important;
}

.MuiCardContent-root.overflow-auto {
	padding: 10px 7px 10px 9px !important;
}

.MuiCardContent-root.overflow-auto:empty {
	display: none;
}

button :focus {
	outline: none !important;
}

* :focus {
	outline: none !important;
}

.MuiTableCell-root {
 font-size: inherit !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;



.title-lg {
	@apply text-lg font-medium text-gray-600
}

.title-xl{
	@apply text-xl font-medium text-gray-600
}
.title-2xl{
	@apply text-2xl font-medium text-gray-600
}

.react-pdf__Document{
	@apply border-2 border-gray-600
}



